.App {
  text-align: center;
}

@font-face {
  font-family: "DJR";
  src: local("Forma\ DJR\ Deck\ Light"),
    url("./fonts/Forma\ DJR\ Deck\ Light.ttf") format("truetype");
}

@font-face {
  font-family: "DJR Bold";
  src: local("Forma\ DJR\ Deck\ Regular"),
    url("./fonts/Forma\ DJR\ Deck\ Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Uncut";
  src: local("UncutSans-Regular"),
    url("./fonts/UncutSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Uncut-Light";
  src: local("UncutSans-Light"),
    url("./fonts/UncutSans-Light.otf") format("opentype");
}

@font-face {
  font-family: "Elza";
  src: local("ElzaNarrow"), url("./fonts/ElzaNarrow.ttf") format("truetype");
}
