* {
  padding: 0;
  box-sizing: border-box;
  color: #000;
}

/* BASICS */
p {
  margin: 0;
}

h1 {
  text-align: center;
}

section {
  padding: 9vh 8vw 0 8vw;
  position: fixed;
}

body {
  font-family: DJR;
  color: #000;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 0;
  scrollbar-width: thin;
  background-image: url(img/album-cover.jpeg);
  background-size: 100vw 100vh;
}

.display-inline * {
  display: inline;
}

#body {
  font-family: DJR;
  font-weight: 400;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

/* HEADER */
#logo {
  position: relative;
  font-family: DJR;
  font-weight: 800;
  font-size: 5vh;
  padding-top: 1vh;
  text-align: center;
  width: 100vw;
  color: #000;
  font-kerning: 1em;
}

#logo a:hover {
  color: #000 !important;
}

#logo a:enabled {
  color: #000 !important;
}

#logo a:active {
  color: #000 !important;
}

/* SOCIALS */
#socials {
  position: fixed;
  padding: 0 0 0 1vw;
  top: 8vh;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 2em;
  height: 100vh;
  z-index: 1;
  color: black;
}

#socials a {
  padding: 10px 3px;
}

/* HAMBURGER */
#hamburger {
  position: absolute;
  float: inline-start;
  padding: 0 0 0 1vw;
  top: 1vh;
  left: 0;
}

.nav-bar {
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  z-index: 1;
  overflow: hidden;
}

#menu {
  position: absolute;
  left: 60px;
  width: 0;
  overflow: hidden;
  transition: width 0.4s ease;
  z-index: 1;
  height: 2.5em;
  font-size: 1em;
}

#menu ul {
  display: flex;
  flex-direction: row;
}

.nav-item {
  display: inline;
  margin: 0 1em;
  font-size: 1.2em;
  z-index: 1;
}

.home-content {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(7, 1fr);
  text-align: left;
  padding: 5vh 2.5vw 0 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}

/* .magictime {
  background-image: url(img/album-cover.jpeg) !important;
} */

/* HOME */
#home {
  background-image: url(img/album-cover.jpeg);
  background-size: 100vw 100vh;
}

.home-link {
  width: 100%;
  height: auto;
}

.home-link a {
  width: 100%;
  height: auto;
  font-size: 2vw;
}

a:hover,
a:focus,
a:active {
  color: #ffffff;
}

#luthier {
  display: none;
  grid-row-start: 1;
  grid-column-start: 3;
  align-content: first baseline;
  text-align: left;
  grid-row-end: auto;
  color: black;
}

#luthier a {
  font-size: 3vw;
}

#about-link {
  grid-row-start: 6;
  grid-column-start: 4;
  align-content: center;
  text-align: center;
  grid-row-end: auto;
  padding-right: 0.2em;
}

#works-link {
  grid-row-start: 4;
  grid-column-start: 7;
  text-align: left;
  grid-row-end: auto;
  margin: 0.3vh 0 0 -1vw;
}

#merch-link {
  grid-row-start: 5;
  grid-column-start: 1;
  text-align: right;
  grid-row-end: auto;
  padding: 0 0.3vw 0 0;
  align-content: center;
}

#lofi-link {
  grid-row-start: 1;
  grid-column-start: 7;
  align-content: center;
  text-align: left;
}

#lofi-inner {
  display: inline-block;
  width: 30%;
  height: 45%;
  background-color: transparent;
  margin: 2vh 0 0 0;
  border-radius: 50%;
}

#lofi-inner:hover {
  cursor: help;
}

#essays-link {
  grid-row-start: 2;
  grid-column-start: 3;
  align-content: first baseline;
  text-align: right;
}

#essays-inner {
  display: inline-block;
  width: 28%;
  height: 30%;
  background-color: transparent;
  margin: 2.5vh 0 0 0;
  border-radius: 50%;
}

#essays-inner:hover {
  cursor: zoom-in;
}

/* LOFI */
#lofi {
  background-image: url(img/lo-fi-album-cover.jpg);
  background-size: 100vw 100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

/* FOOTER */
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1vh 1vw;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 0.9em;
  font-weight: 100;
}

/* OTHER WORKS */
#other-works {
  background-image: url(img/other-works.jpeg);
  background-size: 100vw 100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.vid-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  height: 75vh;
  overflow-y: auto;
  padding: 0 5vw 5vh 5vw;
  scrollbar-width: thin;
  width: 80vw;
}

.vid-item {
  padding: 1em;
  /* flex: 1 0 35%; */
  width: 30vw;
}

iframe {
  border: none;
  height: 35vh !important;
}

.vid-item * {
  width: 100%;
  height: 100%;
}

/* ABOUT */
#about-container {
  background-image: url(img/about.jpeg);
  background-size: 100vw 100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  font-size: 1.2em;
}

#about {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1ex;
  text-align: justify;
  z-index: 1;
  overflow: scroll;
  margin: 0 2vw 10vh 2vw;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 15px;
  padding: 2% 2%;
  line-height: 1.2em;
  max-height: 80vh;
}

#artist-bio {
  grid-column-start: 1;
  grid-row-start: 2;
  padding: 0 2em 0 0;
}

#album-bio {
  grid-column-start: 2;
  grid-row-start: 2;
  padding: 0 0 0 2em;
}

#album-intro {
  grid-column-start: 2;
  grid-row-start: 1;
  padding: 0 2em;
}

/* MERCH */
#merch {
  background-image: url(img/merch.jpeg);
  background-size: 100vw 100vh;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.merch-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  gap: 2em;
  height: 80vh;
  overflow-y: auto;
  padding-bottom: 5vh;
  scrollbar-width: thin;
  width: 80vw;
}

.merch-container h4 {
  margin: 1ex;
}

.merch-container div {
  align-items: center;
  justify-content: center;
}

.merch-container div img {
  width: auto;
  height: 50vh;
}

#equipment {
  font-family: "DJR Bold";
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: thin;
  width: max-content;
  font-size: 1.1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-height: 85vh;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding: 10px 20px;
}

#equipment-header {
  padding-bottom: 1em;
}

/* RESPONSIVE MEDIA QUERIES */

@media screen and (max-width: 1150px) {
  #about {
    margin: 0 0 2vh 0;
    padding: 2vh 2vw;
  }
}

/* Smaller screen */
@media screen and (max-width: 1024px) {
  /* Home page */
  .home-link a {
    font-size: 2.5vw;
  }

  #works-link {
    margin: 1.2vh 0 0 -2.5vw !important;
  }

  /* Put social icons top left horizontal */
  #socials {
    position: absolute;
    padding: 0 1vw 0 0;
    margin-left: 70vw;
    right: 0;
    top: 1vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0.5em;
    height: max-content;
    z-index: 1;
  }

  /* Make nav menu extend below nav bar */
  .nav-bar {
    overflow: visible;
  }

  #menu {
    position: absolute;
    top: 7vh;
    left: 0;
    height: 0;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    transition: width 0.4s ease;
    z-index: 1;
    width: max-content;
    font-size: 1.1em;
  }

  #menu ul {
    display: flex;
    flex-direction: column;
  }

  .nav-item {
    flex-grow: 1;
    flex-shrink: 0;
  }

  #about {
    padding: 2vh 2vw;
  }

  /* OTHER WORKS */
  #other-works {
    background-image: url(img/other-works.jpeg);
    background-size: 100vw 100vh;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .vid-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .vid-item {
    width: 100% !important;
    height: max-content !important;
    padding: 0;
  }

  /* MERCH */
  .merch-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 90vw;
    margin: 3vh 0;
    max-height: 70vh;
  }

  #merch {
    justify-content: flex-start;
  }

  .merch-container div {
    justify-content: center;
  }

  .merch-container div img {
    width: auto;
    height: 40vh;
  }
}

/* Tablet */
@media screen and (max-width: 960px) {
  /* Home page */
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
  }

  .home-link {
    text-align: center !important;
    margin: 5vh 0;
    padding: 1em !important;
  }

  .home-link a {
    font-size: 3em;
    text-align: center !important;
    margin: 0 !important;
  }

  #menu {
    padding: 1em 0 0 0;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 1;
    -webkit-mask-image: -webkit-gradient(
      linear,
      center bottom,
      center top,
      color-stop(0.6, rgba(0, 0, 0, 1)),
      color-stop(1, rgba(0, 0, 0, 0))
    );
    mask-image: -webkit-gradient(
      linear,
      center bottom,
      center top,
      color-stop(0.6, rgba(0, 0, 0, 1)),
      color-stop(1, rgba(0, 0, 0, 0))
    );
    align-items: center;
    justify-content: center;
    margin-top: 1.5vh;
    transition: width 0.4s ease;
    -webkit-animation: width 0.4s ease;
    animation: width 0.4s ease;
  }

  #menu ul {
    margin-bottom: 1vh;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
  }

  .nav-item {
    margin: 0;
    text-align: center;
  }

  /* About page */
  #about-container {
    padding: 6em;
  }

  #about {
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 1em;
    font-size: 1em;
    align-items: flex-start;
    overflow: auto;
    max-height: 80vh;
    padding: 2vh 2vw;
    line-height: 1.2em;
  }

  #artist-bio {
    padding: 0;
  }

  #album-bio {
    padding: 0;
  }

  #album-intro {
    padding: 0;
  }

  footer {
    justify-content: center;
  }

  /* MERCH */
  .merch-container {
    justify-content: flex-start;
    flex-direction: column;
    max-height: 70vh;
  }

  .merch-container div img {
    width: 25vw;
    height: auto;
  }
}

@media screen and (max-width: 850px) {
  /* Put socials in footer */
  #socials {
    font-size: 250% !important;
    top: auto;
    left: auto;
    right: auto;
    position: fixed;
    padding: 0 1vw 3vh 1vw;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 0.5em;
    z-index: 1;
    width: 100vw;
    height: max-content;
    margin: 0;
  }
}

/* Smartphone */
@media screen and (max-width: 640px) and (min-width: 320px) {
  h1 {
    margin-bottom: 1vh;
  }

  section div {
    max-height: 70vh;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh 0 5vh 0;
  }

  .home-link {
    text-align: center !important;
    padding: 1em !important;
  }

  .home-link a {
    font-size: 2em;
    text-align: center !important;
    margin: 0 !important;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 20px;
    padding: 10px 20px;
  }

  .home-link a:hover {
    color: #000;
    background-color: rgba(255, 255, 255, 1);
  }

  #menu {
    margin-top: 0;
    padding-top: 1vh;
  }

  /* About page */
  #about-container {
    padding: 0 10vw;
  }

  #about {
    display: flex;
    flex-direction: column;
    text-align: justify;
    gap: 1em;
    max-height: 65vh;
    margin: 0 0 4vh 0;
    align-items: flex-start;
    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    padding: 1em;
    line-height: 1.2em;
  }

  #about div {
    max-height: max-content;
  }

  /* Other works */
  iframe {
    min-height: max-content !important;
    height: 25vh !important;
  }

  .vid-item {
    padding: 1vh 0;
  }

  .vid-container {
    width: 90vw;
  }

  /* Merch */
  .merch-container {
    justify-content: flex-start;
    flex-direction: column;
    max-height: 65vh;
  }

  .merch-container div img {
    width: 28vw;
    height: auto;
  }
}

@media screen and (max-width: 400px) and (max-height: 850px) {
  section div {
    max-height: 60vh;
  }

  #about {
    margin: 0 0 15vh 0;
  }

  .vid-container {
    height: 55vh !important;
  }

  #home {
    height: 80vh;
  }
}
